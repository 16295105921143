<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>

					<a-form-item label="用户信息" name="userInfo" class="ui-form__item">
						<a-input v-model:value="formState.userInfo" placeholder="请输入用户信息"></a-input>
					</a-form-item>
				</a-row>

				<a-row v-show="showAll">
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="物流方式" name="deliveryType" class="ui-form__item">
						<a-select v-model:value="formState.deliveryType" placeholder="请选择物流方式" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">快递</a-select-option>
							<a-select-option :value="2">到店核销</a-select-option>
							<a-select-option :value="3">无</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="支付方式" name="payMessageType" class="ui-form__item">
						<a-select v-model:value="formState.payMessageType" placeholder="请选择支付方式" allow-clear
							style="width: 195px;">
							<a-select-option :value="'wx_lite'">微信支付</a-select-option>
							<!-- <a-select-option :value="'alipay'">支付宝支付</a-select-option> -->
							<a-select-option :value="'card'">一卡通支付</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="购买时间" name="orderCreateTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.orderCreateTime"></a-range-picker>
					</a-form-item>

					<a-form-item label="售后申请时间" name="createTime" class="ui-form__item">
						<a-range-picker v-model:value="formState.createTime"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a style="margin-bottom: 10px" @click="showAll = !showAll">
						{{ showAll ? '收起' :'展开' }}
						<Icon v-show="!showAll" icon="DownOutlined"></Icon>
						<Icon v-show="showAll" icon="UpOutlined"></Icon>
					</a>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['mall_order_afterSale_import']">
							<exportReport type="shopAfterSaleExportStrategy" :searchData="{saleType: 1, status: (this.activeKey == 0 ? undefined : this.activeKey), ...searchData}"></exportReport>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<a-tabs style="margin-top: 10px;" v-model:activeKey="activeKey" size="small" type="card"
				@change="onListChange">
				<a-tab-pane key="0" tab="全部"></a-tab-pane>
				<a-tab-pane key="1" tab="待处理"></a-tab-pane>
				<a-tab-pane key="2" tab="待退货"></a-tab-pane>
				<a-tab-pane key="3" tab="待退款"></a-tab-pane>
				<a-tab-pane key="4" tab="已拒绝"></a-tab-pane>
				<a-tab-pane key="6" tab="已退款/已退货"></a-tab-pane>
			</a-tabs>

			<div>
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
					:scroll="{ x: 3500 }">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'userInfo'">
							<div>用户昵称：{{ record.userNickname ? record.userNickname : '-' }}</div>
							<div>手机号：{{ record.userPhone ? record.userPhone : '-' }}</div>
						</template>
						<template v-if="column.key === 'orderCreateTime'">
							{{ transDateTime(record.orderCreateTime) }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<!--            <template v-if="column.key === 'orderItemList-goodsNo'">-->
						<!--              <div v-for="item in record.orderItemList" :key="item.id">-->
						<!--                {{ item.goodsNo ? item.goodsNo : '-' }}-->
						<!--              </div>-->
						<!--            </template>-->
						<template v-if="column.key === 'orderItemList-goodsTitle'">
							<div v-for="item in record.orderItemList" :key="item.id">
								{{ item.goodsTitle ? item.goodsTitle : '-' }}
							</div>
						</template>
						<template v-if="column.key === 'orderItemList-skuName'">
							<div v-for="item in record.orderItemList" :key="item.id">
								{{ item.skuName ? item.skuName : '-' }}
							</div>
						</template>
						<template v-if="column.key === 'orderItemList-price'">
							<div v-for="item in record.orderItemList" :key="item.id">
								{{ item.price }}
							</div>
						</template>
						<template v-if="column.key === 'orderItemList-num'">
							<div v-for="item in record.orderItemList" :key="item.id">
								{{ item.num }}
							</div>
						</template>
						<template v-if="column.key === 'payable'">
							{{ preferential[index] + actualPayment[index] }}
						</template>
						<template v-if="column.key === 'deliveryType'">
							<div v-if="record.deliveryType === 1">快递</div>
							<div v-if="record.deliveryType === 2">到店核销</div>
							<div v-if="record.deliveryType === 3">无</div>
						</template>
						<template v-if="column.key === 'cinemaName'">
							{{ record.cinemaName ? record.cinemaName : '-' }}
						</template>
						<template v-if="column.key === 'orderAddress-info'">
							<div v-if="record.deliveryType === 1">
								<p>收货人：{{ record.orderAddress?.name ? record.orderAddress?.name : '-' }}</p>
								<p>联系电话：{{ record.orderAddress?.phone ? record.orderAddress?.phone : '-' }}</p>
								<p>收货地址：{{ record.orderAddress?.address ? record.orderAddress?.address : '-' }}</p>
							</div>
							<div v-if="record.deliveryType === 2">
								-
							</div>
						</template>
						<template v-if="column.key === 'preferential'">
							{{ preferential[index] }}
						</template>
						<template v-if="column.key === 'actualPayment'">
							{{ actualPayment[index] }}
						</template>

						<template v-if="column.key === 'fees'">
							<div v-if="record.payMessageType === 'wx_lite'">{{ (record.payment * 0.0054).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'predict'">
							<div v-if="record.payMessageType === 'wx_lite'">{{ (record.payment - (record.payment * 0.0054)).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>

						<template v-if="column.key === 'payMessageType'">
							<div v-if="record.payMessageType === 'wx_lite' || record.payMessageType === 'zero'">微信支付</div>
							<div v-else-if="record.payMessageType === 'alipay'">支付宝支付</div>
							<div v-else-if="record.payMessageType === 'card'">一卡通支付</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'info'">
							<div>
								<div>申请时间：{{ transDateTime(record.createTime) }}</div>
								<div>售后原因：{{ record.reason }}</div>
								<div v-if="record.auditTime">
									<div>审核人：{{ record.processName || '-' }}</div>
									<div>审核时间：{{ transDateTime(record.auditTime) }}</div>
								</div>
								<div v-if="record.refundTime">退款时间：{{ transDateTime(record.refundTime) }}</div>
							</div>
						</template>
						<template v-if="column.key === 'orderAfterSale-status'">
							<div v-if="record.status === 1">处理中</div>
							<div v-if="record.status === 2">待用户发货</div>
							<div v-if="record.status === 3">用户已发货</div>
							<div v-if="record.status === 4">
								拒绝
								<div >拒绝理由：{{ record.rejectReason || '-' }}</div>
							</div>
							<div v-if="record.status === 5">撤回</div>
							<div v-if="record.status === 6">退款成功</div>
							<div v-if="record.status === 7">改签成功</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['mall_order_afterSale_viewApplication']"
											@click="onShowReturnInfo(record)">
											<a-menu-item>
												查看退货申请
											</a-menu-item>
										</div>
										<div v-permission="['mall_order_afterSale_agreeAfterSale']"
											@click="onShowConfirmAudit(record, index)" v-if="record.status === 1">
											<a-menu-item>
												同意售后
											</a-menu-item>
										</div>
										<div v-permission="['mall_order_afterSale_refuseAfterSale']"
											@click="onShowRejectAudit(record)" v-if="record.status === 1">
											<a-menu-item>
												拒绝售后
											</a-menu-item>
										</div>
										<div v-permission="['mall_order_afterSale_agreeRefund']"
											@click="onShowReceiveConfirm(record, index)"
											v-if="record.status === 2 || record.status === 3">
											<a-menu-item>
												同意退款
											</a-menu-item>
										</div>
										<div v-permission="['mall_order_afterSale_refuseRefund']"
											@click="onShowReceiveReject(record)"
											v-if="record.status === 2 || record.status === 3">
											<a-menu-item>
												拒绝退款
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>

		<a-modal v-model:visible="showModal" title="退货申请" width="700px">
			<div>
				<a-descriptions :column="2">
					<a-descriptions-item
						label="申请人">{{ returnInfo.userNickname ? returnInfo.userNickname : '-' }}</a-descriptions-item>
					<a-descriptions-item
						label="手机号">{{ returnInfo.userPhone ? returnInfo.userPhone : '-' }}</a-descriptions-item>
					<a-descriptions-item
						label="订单号">{{ returnInfo.orderNo ? returnInfo.orderNo : '-' }}</a-descriptions-item>
					<a-descriptions-item
						label="申请原因">{{ returnInfo.reason ? returnInfo.reason : '-' }}</a-descriptions-item>
					<a-descriptions-item
						label="退款金额">{{ returnInfo.refund ? returnInfo.refund : '-' }}</a-descriptions-item>
					<a-descriptions-item
						label="物流公司">{{ returnInfo.logisticsCompany ? returnInfo.logisticsCompany : '-' }}</a-descriptions-item>
					<a-descriptions-item
						label="物流单号">{{ returnInfo.logisticsNo ? returnInfo.logisticsNo : '-' }}</a-descriptions-item>
				</a-descriptions>
			</div>
			<div>
				<h3 v-if="returnInfo.Traces"
					style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 18px;">订单追踪
				</h3>
				<div v-for="item in returnInfo.Traces" :key="item" style="margin-top: 20px;">
					<div>{{ item.AcceptStation ? item.AcceptStation : '-' }}</div>
					<div>{{ transDateTime(item.AcceptStation) }}</div>
				</div>
			</div>
			<template #footer>
				<a-button type="primary" @click="showModal = false">确认</a-button>
			</template>
		</a-modal>

		<a-modal v-model:visible="showNotesInfo" :title="rejectInfo" width="400px" @ok="onOk">
			<a-form :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 14, xxl: 13 }">
				<a-form-item label="备注信息" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="notesInfo"></a-input>
				</a-form-item>
				<!--        <a-form-item label="操作人" :rules="[{required: true, message: '必填项不允许为空'}]">-->
				<!--          <a-input v-model:value="user"></a-input>-->
				<!--        </a-form-item>-->
			</a-form>
		</a-modal>

		<a-modal v-model:visible="showReturnInfo" :title="'确定要' + title + '吗'" width="400px" @ok="onReturnOk">
			<a-form>
				<a-form-item label="订单号">
					{{ data.orderNo }}
				</a-form-item>
				<a-form-item label="实付金额">
					{{ data.actualPayment }}
				</a-form-item>
				<a-form-item label="退款金额">
					{{ data.refundTotal }}
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportReport from '@/components/exportReport/exportReport.vue';
	import {
		confirmAuditOrderAfterSale,
		getOrderAfterSaleDetail,
		getOrderAfterSaleList,
		getOrderTraces,
		receiveConfirmOrderAfterSale,
		receiveRejectOrderAfterSale,
		rejectAuditOrderAfterSale
	} from "@/service/modules/mall";
	import {
		getCinemaAllList
	} from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
			exportReport
		},
		data() {
			return {
				title: '',
				user: '',
				notesInfo: '',
				rejectInfo: '',
				activeKey: '0',
				showAll: false,
				showSend: false,
				showModal: false,
				showReturnInfo: false,
				showNotesInfo: false,
				isEdit: false,
				isSee: false,
				id: 0,
				rowSelectionType: 'checkbox',
				cinemaAllList: [],
				selectedRowKeys: [],
				loading: false,
				returnInfo: {},
				searchData: {},
				formState: {},
				modalRef: {},
				sendModelRef: {},
				columns: [{
						title: '订单号',
						dataIndex: 'orderNo',
						width: 180
					}, {
						title: '购买时间',
						key: 'orderCreateTime',
						width: 190
					}, {
						title: '用户信息',
						key: 'userInfo',
						width: 250
					},
					// {
					//   title: '商品编码',
					//   key: 'orderItemList-goodsNo',
					// }
					, {
						title: '商品信息',
						key: 'orderItemList-goodsTitle',
					}, {
						title: '规格',
						key: 'orderItemList-skuName',
					}, {
						title: '单价',
						key: 'orderItemList-price',
						width: 100
					}, {
						title: '数量',
						key: 'orderItemList-num',
						width: 100
					}, {
						title: '配送方式',
						key: 'deliveryType',
						width: 150
					}, {
						title: '所属影院',
						key: 'cinemaName',
					}, {
						title: '收货信息',
						key: 'orderAddress-info',
					}, {
						title: '优惠金额',
						key: 'preferential',
						width: 100
					}, {
						title: '应付金额',
						key: 'payable',
						width: 100
					}, {
						title: '实付金额',
						key: 'actualPayment',
						width: 100
					}, {
						title: '支付类型',
						key: 'payMessageType',
						width: 150
					}, {
						title: '售后信息',
						key: 'info'
					}, {
						title: '售后状态',
						key: 'orderAfterSale-status',
						width: 150
					},
					// , {
					//   title: '订单状态',
					//   key: 'status',
					// }
					, {
						title: '操作',
						key: 'action',
						fixed: 'right',
						width: 150
					}
				],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						if (this.activeKey === '0') {
							this.getData();
						} else {
							this.getData(this.activeKey);
						}
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						if (this.activeKey === '0') {
							this.getData();
						} else {
							this.getData(this.activeKey);
						}
					}
				},
			}
		},
		computed: {
			actualPayment() {
				let actualPaymentList = [];
				if (this.list && this.list.length) {
					this.list.forEach(item => {
						let num = 0;
						if (item.orderItemList && item.orderItemList.length) {
							item.orderItemList.forEach(i => {
								num += i.payment;
							})
						}
						actualPaymentList.push(num);
					})
				}
				return actualPaymentList;
			},
			preferential() {
				let preferentialList = [];
				if (this.list && this.list.length) {
					this.list.forEach(item => {
						let num = 0;
						if (item.orderItemList && item.orderItemList.length) {
							item.orderItemList.forEach(i => {
								num += i.couponDeduction;
							})
						}
						preferentialList.push(num);
					})
				}
				return preferentialList;
			}
		},
		created() {
			this.onSearch();
			this.getAllCinemaList();
		},
		methods: {
			reset() {
				this.activeKey = '0';
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.activeKey = '0';
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.activeKey === '0') {
					this.getData();
				} else {
					this.getData(this.activeKey);
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			async getData(status) {
				if (this.formState.createTime && this.formState.createTime.length === 2) {
					this.searchData.createStartTime = this.moment(this.formState.createTime[0].startOf('day')).unix();
					this.searchData.createEndTime = this.moment(this.formState.createTime[1].endOf('day')).unix();
				}
				if (this.formState.orderCreateTime && this.formState.orderCreateTime.length === 2) {
					this.searchData.orderCreateStartTime = this.moment(this.formState.orderCreateTime[0].startOf('day')).unix();
					this.searchData.orderCreateEndTime = this.moment(this.formState.orderCreateTime[1].endOf('day')).unix();
				}
				delete this.searchData.createTime;
				delete this.searchData.orderCreateTime;
				this.loading = true;
				try {
					let ret = await getOrderAfterSaleList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						saleType: 1,
						status: status,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onSelectChange(record, selected) {
				if (this.rowSelectionType === 'checkbox') {
					if (selected) {
						// this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						// this.selectedRowData = this.selectedRowData.filter(item => {
						// 	return item.id !== record.id;
						// });
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							// this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							// this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onListChange() {
				if (this.activeKey === '0') {
					this.getData();
				} else {
					this.getData(this.activeKey);
				}
			},
			async onShowReturnInfo(item) {
				this.loading = true;
				try {
					let ret = await getOrderAfterSaleDetail({
						id: item.id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.returnInfo = ret.data;
						this.getOrderTraces(item.id);
						this.showModal = true;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async onConfirmAudit() {
				this.loading = true;
				try {
					let ret = await confirmAuditOrderAfterSale({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.activeKey = '0';
						this.getData();
						this.showReturnInfo = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async onRejectAudit() {
				this.loading = true;
				try {
					let ret = await rejectAuditOrderAfterSale({
						id: this.id,
						remark: this.notesInfo,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.activeKey = '0';
						this.getData();
						this.showNotesInfo = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async onReceiveConfirm() {
				this.loading = true;
				try {
					let ret = await receiveConfirmOrderAfterSale({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.activeKey = '0';
						this.showReturnInfo = false;
						this.getData();
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async onReceiveReject() {
				this.loading = true;
				try {
					let ret = await receiveRejectOrderAfterSale({
						id: this.id,
						remark: this.notesInfo,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.activeKey = '0';
						this.getData();
						this.showNotesInfo = false;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onShowRejectAudit(item) {
				this.notesInfo = '';
				this.id = item.id;
				this.rejectInfo = '拒绝售后';
				this.showNotesInfo = true;
			},
			onShowReceiveReject(item) {
				this.notesInfo = '';
				this.id = item.id;
				this.rejectInfo = '拒绝退款';
				this.showNotesInfo = true;
			},
			onOk() {
				if (!this.notesInfo) {
					this.$message.error('备注信息不能为空！');
					return;
				}
				// if(!this.user) {
				//   this.$message.error('操作人不能为空！');
				//   return;
				// }
				if (this.rejectInfo === '拒绝退款') {
					this.onReceiveReject();
				}
				if (this.rejectInfo === '拒绝售后') {
					this.onRejectAudit();
				}
			},
			onShowConfirmAudit(item, index) {
				this.id = item.id;
				this.title = '同意售后';
				this.data = {
					orderNo: item.orderNo,
					actualPayment: this.actualPayment[index],
					refundTotal: item.refundTotal,
				}
				this.showReturnInfo = true;
			},
			onShowReceiveConfirm(item, index) {
				this.id = item.id;
				this.title = '同意退款';
				this.data = {
					orderNo: item.orderNo,
					actualPayment: this.actualPayment[index],
					refundTotal: item.refundTotal,
				}
				this.showReturnInfo = true;
			},
			onReturnOk() {
				if (this.title === '同意售后') {
					this.onConfirmAudit();
				}
				if (this.title === '同意退款') {
					this.onReceiveConfirm();
				}
			},
			async getOrderTraces(id) {
				this.loading = true;
				try {
					let ret = await getOrderTraces({
						orderId: id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.returnInfo = {
							...this.returnInfo,
							Traces: ret.data.Traces
						}
					}
				} catch (e) {
					this.loading = false;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}

	/* 下划线 */
	:deep(.ant-tabs-ink-bar) {
		background-color: #1890ff;
	}

	/* 选中颜色 */
	:deep(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
		color: #1890ff;
	}
</style>
